import React from 'react';
import logo from './res/IMG_1293.jpg';

import ReactGA from 'react-ga';
import Link from "./Link/Link"
import CollapsableSection from "./CollapsableSection/CollapsableSection"
import './App.css';

ReactGA.initialize('UA-127647347-3', {
  debug: false
});

// export NODE_OPTIONS=--openssl-legacy-provider


ReactGA.pageview(window.location.pathname + window.location.search);

class App extends React.Component {

  constructor(props) {
    super(props)
    this.viewResume = this.viewResume.bind(this);
    this.determineLinkColors = this.determineLinkColors.bind(this);
    this.state = {
      colorAccent: "blue"
    }
  }

  componentDidMount() {
    this.determineLinkColors();
  }

  onClick(event) {
    var text = event.target.innerText || event.target.textContent;
    // console.log("Clicked the link", text, "logging event in Google Analytics :) ")
    ReactGA.event({
      category: 'User',
      action: text
    });
  }

  onClickPaper(event, paperName) {
    ReactGA.event({
      category: 'User',
      action: paperName
    });
  }

  viewResume() {
    // console.log("Clicked view resume => logging event in Google Analytics :)");
    ReactGA.event({
      category: 'User',
      action: 'Clicked View Resume'
    });
  }

  determineLinkColors() {
    const date = new Date();
    const days = date.getDate();
    //console.log("Day of the month is: " + days);
    const mod = days % 3;
    //console.log("Mod is: " + mod);

    if (mod == 0) {
      this.setState({
        colorAccent: "blue"
      })
    }
    if (mod == 1) {
      this.setState({
        colorAccent: "pink"
      })
    }
    if (mod == 2) {
      this.setState({
        colorAccent: "green"
      })
    }
  }

  render() {

    return (
      <div className="App">

        <div className="padding"></div>

        <header className="container">

          <div className="left">
            <img className="profile" src={logo}></img>

            <div className="name-email-box">
              <p className="name">Sreehari Rammohan</p>
              <p>view <Link onClick={this.viewResume} link={process.env.PUBLIC_URL + "/Sreehari-Resume.pdf"} type="application/pdf" target="_blank" hover="hoverable" color={this.state.colorAccent}>resume</Link></p>
            </div>

          </div>

          <div className="right">
            <p>
            I'm a CS PhD student at Columbia University advised by <Link onClick={this.onClick} link="https://www.cs.columbia.edu/~vondrick/" target="_blank" color={this.state.colorAccent} hover="hoverable">Carl Vondrick</Link> in New York City.
            </p>

            <p>Before, I did my undergrad at Brown University where I studied Applied Math and CS and did research at the Intelligent Robot Lab advised primarily by <Link onClick={this.onClick} link="https://cs.brown.edu/people/gdk/" target="_blank" color={this.state.colorAccent} hover="hoverable">George Konidaris</Link>.</p>
            
            <p>I grew up in the Bay Area.</p>

            <p>[first name] [last name] [at] gmail [dot] com</p>
      
            <p>
              <strong>Select Publications:</strong>
              <br />

              <div>
                <p className="publication_title">
                Composable Interaction Primitives: A Structured Policy Class for Efficiently Learning Sustained-Contact Manipulation Skills
                  <ul className="publication_ul">
                      <li>Ben Abbatematteo, Eric Rosen, Skye Thompson, Mete Tuluhan Akbulut, Sreehari Rammohan, George Konidaris</li>
                      <li>2024 IEEE International Conference on Robotics and Automation (ICRA 2024)</li>
                      <li className="indentedLi">view <Link onClick={(event) => this.onClickPaper(event, "CIPs")} link={process.env.PUBLIC_URL + "/papers/cips_preprint.pdf"} target="_blank" color={this.state.colorAccent} hover={"hoverable"}>preprint</Link></li>
                  </ul>
                </p>
              </div>

              <div>
                <p className="publication_title">
                Hierarchical Empowerment: Towards Tractable Empowerment-Based Skill-Learning
                  <ul className="publication_ul">
                      <li>Andrew Levy, Sreehari Rammohan, Alessandro Allievi, Scott Niekum, George Konidaris</li>
                      <li>NeurIPS 2023: Workshop on Goal-Conditioned Reinforcement Learning</li>
                      <li className="indentedLi">view <Link onClick={(event) => this.onClickPaper(event, "Empowerement")} link={"https://arxiv.org/abs/2307.02728"} target="_blank" color={this.state.colorAccent} hover={"hoverable"}>paper</Link></li>
                  </ul>
                </p>
              </div>

              <div>
                <p className="publication_title">
                Effectively Learning Initiation Sets in Hierarchical Reinforcement Learning
                  <ul className="publication_ul">
                      <li>Akhil Bagaria, Ben Abattamateo, Omer Gottesman, Sreehari Rammohan, George Konidaris</li>
                      <li>NeurIPS 2023: Thirty-Seventh Conference on Neural Information Processing Systems</li>
                      <li className="indentedLi">view <Link onClick={(event) => this.onClickPaper(event, "Initiation Set Learning")} link={process.env.PUBLIC_URL + "/papers/initiation-set-learning-camera-ready.pdf"} target="_blank" color={this.state.colorAccent} hover={"hoverable"}>paper</Link></li>
                  </ul>
                </p>
              </div>

              <div>
                <p className="publication_title">
                Q-Functionals For Value-Based Continuous Control
                  <ul className="publication_ul">
                      <li>Sreehari Rammohan*, Bowen He*, Sam Lobel*, Shangqun Yu, George Konidaris</li>
                      <li>Thirty-Seventh AAAI Conference on Artificial Intelligence</li>
                      <li className="indentedLi">view  conference <Link onClick={(event) => this.onClickPaper(event, "Q-Functionals AAAI Paper")} link={process.env.PUBLIC_URL + "/papers/q-functionals-aaai-camera-ready.pdf"} target="_blank" color={this.state.colorAccent} hover={"hoverable"}>paper</Link></li>
                      <li>RLDM 2022: The Multi-disciplinary Conference on Reinforcement Learning and Decision Making</li>
                      <li className="indentedLi">view  workshop <Link onClick={(event) => this.onClickPaper(event, "Q-Functionals RLDM")} link={process.env.PUBLIC_URL + "/papers/q-functionals-rldm.pdf"} target="_blank" color={this.state.colorAccent} hover={"hoverable"}>paper</Link></li>
                  </ul>
                </p>
              </div>

              <div>
                <p className="publication_title">Rainbow RBF-DQN
                  <ul className="publication_ul">
                      <li>Sreehari Rammohan, Bowen He, Shangqun Yu, Eric Hsiung, Eric Rosen, George Konidaris</li>
                      <li>RLDM 2022: The Multi-disciplinary Conference on Reinforcement Learning and Decision Making</li>
                      <li className="indentedLi">view <Link onClick={(event) => this.onClickPaper(event, "Rainbow RBF-DQN RLDM")} link={process.env.PUBLIC_URL + "/papers/rainbow-rldm.pdf"} target="_blank" color={this.state.colorAccent} hover={"hoverable"}>abstract</Link>,
                      <Link onClick={(event) => this.onClickPaper(event, "Rainbow RBF-DQN Full Paper")} link={process.env.PUBLIC_URL + "/papers/rainbow-full.pdf"} target="_blank" color={this.state.colorAccent} hover={"hoverable"}>paper</Link>,
                      <Link onClick={(event) => this.onClickPaper(event, "Rainbow RBF-DQN Code")} link="https://github.com/SreehariRamMohan/rainbow_RBFDQN" target="_blank" color={this.state.colorAccent} hover={"hoverable"}> code </Link></li>
                  </ul>
                </p>
              </div>

              <div>
                <p className="publication_title">Does DQN really learn? Exploring adversarial training schemes in Pong
                  <ul className="publication_ul">
                      <li>Bowen He, Sreehari Rammohan, Jessica Forde, Michael Littman</li>
                      <li>RLDM 2022: The Multi-disciplinary Conference on Reinforcement Learning and Decision Making</li>
                      <li className="indentedLi">view  <Link onClick={(event) => this.onClickPaper(event, "Does DQN really learn? Exploring adversarial training schemes in Pong")} link={process.env.PUBLIC_URL + "/papers/pong-rldm.pdf"} target="_blank" color={this.state.colorAccent} hover={"hoverable"}>paper</Link></li>
                  </ul>
                </p>
              </div>

              <div>
                <p className="publication_title">Value-Based Reinforcement Learning for Continuous Control Robotic Manipulation in Multi-Task Sparse Reward Settings
                  <ul className="publication_ul">
                      <li>Sreehari Rammohan*, Shangqun Yu*, Bowen He*, Eric Hsiung*, Eric Rosen, Stefanie Tellex, George Konidaris</li>
                      <li>2021 Workshop on Advancing Artificial Intelligence and Manipulation for Robotics (RSS) </li>
                      <li className="indentedLi">view <Link onClick={(event) => this.onClickPaper(event, "Continuous Control Robotic Manipulation in Multi-Task Sparse Reward Settings")} link="https://arxiv.org/abs/2107.13356" target="_blank" color={this.state.colorAccent} hover={"hoverable"}>paper</Link>, <Link onClick={this.onClick} link={process.env.PUBLIC_URL + "/RSS_Workshop_Poster.pdf"} target="_blank" color={this.state.colorAccent} hover={"hoverable"}>poster</Link></li>
                  </ul>
                </p>
              </div>

              <div>
                <p className="publication_title">Atrial Fibrillation Stratification Via Super-resolution Analysis of Fibrillatory Waves
                <ul className="publication_ul">
                    <li>Saumitra Mishra, Sreehari Rammohan, Khalid Z. Rajab, Gurpreet Dhillon, Pier Lambiase, Ross Hunter, Elaine Chew</li>
                    <li>2019 Computing in Cardiology</li>
                    <li className="indentedLi">view <Link onClick={(event) => this.onClickPaper(event, "AF Stratification")} link="https://ieeexplore.ieee.org/abstract/document/9005797" target="_blank" color={this.state.colorAccent} hover={"hoverable"}>paper</Link></li>
                  </ul>
                </p>
              </div>

            </p>

            <div className="quoteBox">
            <p className="quoteText">"So, they went off together. But wherever they go, and whatever happens to them on the way, in that enchanted place on the top of the Forest, a little boy and his Bear will always be playing."</p> 
          </div>

          </div>

          

        </header>

        {/* <div className="footer">
          <hr></hr>
          <div className="footer-container">
            <p className="text-footer"></p>
          </div>
        </div> */}
      </div>
    );
  }
}

export default App;
