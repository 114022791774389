import React from 'react';
import './Link.css'

function Link(props) {
    return (
        <a href={props.link}
        target="_blank" 
        onClick={props.onClick}
        className={props.color + " " + props.hover}>{props.children}</a>
    )
}

export default Link;